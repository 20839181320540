<template>
	<div class="grid">
		<div class="col-12 md:col-4">
			<div class="card" v-show="displayGroupPanel">
				<DataTable :value="enterpriseList" :paginator="true" class="p-datatable-gridlines" :rows="10"
					dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll" v-model:selection="selectedEnterprise"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']"
					@rowSelect="onRowSelect" @rowUnselect="onRowUnselect" selectionMode="single" stateStorage="session"
					stateKey="dt-state-demo-session">

					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="filters1['global'].value" placeholder="关键词搜索"
										style="width: 100%" />
								</span>
							</div>
							<div class="col-6 text-right">
								<Button label="新增" icon="pi pi-plus" class="mr-2" v-if="userInfo.admin != 0"
									@click="openAddEnterprise('add', '')" />
							</div>							
							<Dialog :header="enterpriseOpName" v-model:visible="displayAddEnterprise"
								:style="{ width: '30vw' }" :modal="true">
								<div class="field p-fluid">
									<label for="enterpriseName">企业名称</label>
									<InputText id="enterpriseName" type="text" class="p-error"
										v-model="enterpriseForm.enterpriseinput" aria-describedby="username-help" />
									<small id="username-help" class="p-error"
										v-show="addEnterpriseNameError">请输入正确的企业名称，由中文、英文或者数字组成</small>
								</div>
								<div class="field p-fluid">
									<label for="managerName">管理员账号</label>
									<InputText id="managerName" class="p-error" v-model="enterpriseForm.adminNumber"
										aria-describedby="managername-help" />
									<small id="managername-help" class="p-error"
										v-show="addManagerNameError">请输入正确的管理员账号</small>
								</div>
								<div class="field p-fluid">
									<label for="managerPwd">密码</label>
									<Password id="managerPwd" class="p-error" aria-describedby="managerpwd-help"
										v-model="enterpriseForm.adminPassword" toggleMask></Password>
									<small id="managerpwd-help" class="p-error"
										v-show="addManagerPasswordError">请输入超过6位密码</small>
								</div>

								<template #footer>
									<Button label="取消" icon="pi pi-times" @click="closeAddEnterprise"
										class="p-button-text" />
									<Button label="确定" icon="pi pi-check" @click="doAddEnterprise" autofocus />
								</template>
							</Dialog>
						</div>
					</template>
					<template #empty>
						No customers found.
					</template>
					<template #loading>
						Loading customers data. Please wait.
					</template>
					<Column field="name" header="企业" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.name }}
						</template>
					</Column>
					<Column header="操作" style="min-width:10rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button icon="pi pi-pencil"
									class="p-button-rounded p-button-success p-button-outlined mr-2"
									@click="openAddEnterprise('edit', data)" />
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined"
									@click="delEnterprise(data, $event)" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="col-12 md:col-8">
			<div class="card">
				<!-- <Breadcrumb :home="groupBreadHome" :model="groupBreadItems" class="mb-3">
					<template #item="{ item }">
						<a href="#" @click.prevent="navigate(item)" class="p-menuitem-link">
							<span :class="item.style"><i :class="item.icon"></i>{{ item.label }}</span>
						</a>
					</template>
				</Breadcrumb> -->
				<div v-if="sideindex == -1">&lt;&lt; 单击左边企业，显示场地信息</div>
				<router-view v-if="sideindex != -1" @updateData="updateDataHandler"
					@updateEnterpriseList="getEnterpriseList" :key="$route.fullPath"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';

export default {
	data() {
		return {
			// ---- start data source ----
			sideindex: -1,
			enterpriseList: null,
			isAdmin: false,
			ischild: false,
			selectedEnterprise: null,
			affEnterName: '',
			noHave: false,
			groupList: [],
			displayAddEnterprise: false,
			displayGroupPanel: true,

			addEnterpriseNameError: false,
			addManagerNameError: false,
			addManagerPasswordError: false,
			enterpriseForm: {
				enterpriseinput: '',
				adminNumber: '',
				adminPassword: '',
				id: ''
			},
			enterpriseOpName: '新增企业',
			// ---- end data source ----


			customer1: null,
			customer2: null,
			customer3: null,
			filters1: null,
			displayAddGroup: false,
			filters2: {},
			loading1: true,
			loading2: true,
			idFrozen: false,
			products: null,
			expandedRows: [],
			statuses: [
				'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
			],
			representatives: [
				{ name: "Amy Elsner", image: 'amyelsner.png' },
				{ name: "Anna Fali", image: 'annafali.png' },
				{ name: "Asiya Javayant", image: 'asiyajavayant.png' },
				{ name: "Bernardo Dominic", image: 'bernardodominic.png' },
				{ name: "Elwin Sharvill", image: 'elwinsharvill.png' },
				{ name: "Ioni Bowcher", image: 'ionibowcher.png' },
				{ name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
				{ name: "Onyama Limba", image: 'onyamalimba.png' },
				{ name: "Stephen Shaw", image: 'stephenshaw.png' },
				{ name: "XuXue Feng", image: 'xuxuefeng.png' }
			],
			groupBreadHome: { icon: 'pi pi-home', label: '', to: '', style: 'p-menuitem-text' },
			groupBreadItems: [],
			groupBreadIndex: 0,
			rowSelected: false,
			userInfo: null,
		}
	},
	watch: {
		selectedCustomer1(newVal, oldVal) {
			console.log('newval = ' + newVal);
			console.log('oldVal = ' + oldVal);
		}
	},
	customerService: null,
	productService: null,
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();

		this.userInfo = JSON.parse(window.localStorage.getItem('userinfo'))
		console.log(this.userInfo)
	},
	mounted() {

		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;

		// ---- start data source ----
		this.initVars();
		this.getEnterpriseList();

		var _grp_bread_items = window.sessionStorage.getItem('groupBreadItems')

		if (_grp_bread_items == '')
			window.sessionStorage.setItem('groupBreadItems', JSON.stringify(this.groupBreadItems));
		else
			this.groupBreadItems = JSON.parse(_grp_bread_items);
		this.updateDataHandler();
		// ---- end data source ----
	},
	beforeUnmount() {
		window.sessionStorage.setItem('groupBreadIndex', -1);
		window.sessionStorage.setItem('groupBreadItems', '');
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},

		initVars() {
			this.groupBreadHome.label = '';
			this.enterpriseForm.enterpriseinput = '';
			this.enterpriseForm.adminNumber = '';
			this.enterpriseForm.adminPassword = '';
			this.enterpriseForm.id = '';
			this.sideindex = -1;
			this.selectedEnterprise = null;
		},

		// 获取企业列表
		getEnterpriseList() {
			this.$http('/api/admin/enterprise/index', {
				name: '',
				page_size: 100,
			}).then(res => {
				if (res.code == 200) {
					this.enterpriseList = res.data;
					//this.getRightList()
				} else {
					//this.$message.error(res.msg)
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.msg, life: 3000 });
				}
			})
		},

		// 确定新增企业
		doAddEnterprise() {
			if (this.ischild) {
				this.doAddGroup()
			} else {
				//暂时先把验证去掉，后面再加上去
				this.$http('/api/admin/enterprise/create', {
					name: this.enterpriseForm.enterpriseinput,
					tel: this.enterpriseForm.adminNumber,
					pwd: this.enterpriseForm.adminPassword,
					enterprise_pid: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
					id: this.enterpriseForm.id
				}).then(res => {
					if (res.code == 200) {
						this.$toast.add({ severity: 'success', summary: '操作成功', detail: '操作\'' + this.enterpriseForm.enterpriseinput + ' \'成功', life: 2000 });
						this.getEnterpriseList();
						this.displayAddEnterprise = false;
					} else {
						this.$toast.add({ severity: 'error', summary: '操作失败', detail: '\'' + this.enterpriseForm.enterpriseinput + '\':' + res.msg, life: 2000 });
					}
				})

				this.displayAddEnterprise = false;
			}
		},

		doAddGroup() {
			if (this.sideindex == -1 || this.enterpriseList[this.sideindex].id == '') {
				this.$toast.add({ severity: 'error', summary: '请选择父企业' , life: 3000 });
				return false;
			}

			this.$http('/api/admin/enterprise/create', {
				name: this.enterpriseForm.enterpriseinput,
				tel: this.enterpriseForm.adminNumber,
				pwd: this.enterpriseForm.adminPassword,
				enterprise_pid: this.enterpriseList[this.sideindex].id,
				id: this.enterpriseForm.id
			}).then(res => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 3000  });
					this.displayAddEnterprise = false;
					this.getG();
					this.getEnterpriseList();
				} else {
					this.$toast.add({ severity: 'error', summary: '操作失败' , life: 3000 });
				}
			})
		},

		delEnterprise(item, event) {
			this.$confirm.require({
				target: event.currentTarget,
				message: '确认刪除企业吗？',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/enterprise/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功', detail: '删除\'' + this.enterpriseForm.enterpriseinput + ' \'成功', life: 2000 });
							this.getEnterpriseList();
							this.initVars();
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败', detail: '\'' + this.enterpriseForm.enterpriseinput + '\':' + res.msg, life: 2000 });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},

		// 场地删除
		delGroup(item, event) {
			this.$confirm.require({
				message: '确认刪除集群吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/enterprise/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功', life: 3000  });
							this.displayAddEnterprise = false;
							this.getGroupList();
							this.getEnterpriseList();
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 2000 });
						}
					})
				},
				reject: () => {
					this.displayAddEnterprise = false;
					this.getGroupList();
				}
			});
		},

		closeAddEnterprise() {
			this.displayAddEnterprise = false;
		},

		openAddEnterprise(type, item) {
			console.log(item)
			if (type == 'add') {
				this.enterpriseOpName = '新增企业';
				this.enterpriseForm = {
					enterpriseinput: '',
					adminNumber: '',
					adminPassword: ''
				}
			} else {
				this.enterpriseOpName = '编辑企业';
				this.enterpriseForm = {
					enterpriseinput: item.name,
					adminNumber: item.tel,
					adminPassword: item.pwd,
					id: item.id
				}
			}

			console.log(this.enterpriseForm)
			this.ischild = false
			this.addenterprise = true
			this.displayAddEnterprise = true;
		},

		openAddGroup(type, item) {
			if (this.sideindex == -1 || this.enterpriseList[this.sideindex].id == '') {
				this.$toast.add({ severity: 'error', summary: '请选择父企业' , life: 3000 });
				return false;
			}

			var company_name = this.enterpriseList[this.sideindex].name;

			this.ischild = true
			if (type == 'add') {
				this.enterpriseOpName = '新增集群' + ' [' + company_name + ']';
				this.enterpriseForm = {
					enterpriseinput: '',
					adminNumber: '',
					adminPassword: ''
				}
			} else {
				this.enterpriseOpName = '编辑集群' + ' [' + company_name + '->' + item.name + ']';
				this.enterpriseForm = {
					enterpriseinput: item.name,
					adminNumber: item.tel,
					adminPassword: item.pwd,
					id: item.id
				}
			}

			//集群与企业共用页面			
			this.displayAddEnterprise = true;
		},

		// viewFields(enterprise, cluster, enterprise_name, cluster_name) {
		//     //this.$router.push({ name: 'setup', params: { name: this.enterprisearr[this.sideindex].name, id: this.enterprisearr[this.sideindex].id }})
		//     window.localStorage.setItem('enteridx', this.sideindex)

		//     ////清空groupBreadItems后面的数据，并配置当前选项
		//     var _grp_brd_idx = parseInt(this.groupBreadIndex, 10) + 1;
		//     var _grp_bread_items = JSON.parse(window.sessionStorage.getItem('groupBreadItems'));

		//     _grp_bread_items = _grp_bread_items.splice(0, _grp_brd_idx);

		//     var to_page = '/group/field';
		//     var _params = {
		//         ent_id: enterprise,
		//         ent_name: enterprise_name,
		//         cluster_id: cluster,
		//         cluster_name: cluster_name,
		//         bread_index: -1,
		//         back_index: _grp_brd_idx-1
		//     };

		//     //导航到Cluster页面
		//     this.$router.push({
		//         path: to_page,
		//         query: _params
		//     });

		//     this.$emit('updateData');
		// },

		onRowSelect(event) {
			//this.breadcrumbHome.label = event.data.name;
			//this.$toast.add({ severity: 'success', summary: '选择企业', detail: '名称: ' + event.data.name, life: 2000 });			
			this.sideindex = event.index;

			//清空groupBreadItems，并配置当前选项
			this.groupBreadItems = [];
			this.groupBreadIndex = 0;
			this.groupBreadItems.push({
				label: event.data.name,
				to: '/group/field',
				style: 'p-menuitem-text',
				params: {
					ent_index: this.sideindex,
					ent_id: event.data.id,
					ent_name: event.data.name,
					cluster_id: -1,
					cluster_name: '',
					bread_index: this.groupBreadIndex,
					back_index: -1,
					date: new Date().getTime()
				}
			});

			//保存数据到session
			window.sessionStorage.setItem('groupBreadIndex', this.groupBreadIndex);
			window.sessionStorage.setItem('groupBreadItems', JSON.stringify(this.groupBreadItems));

			this.updateDataHandler();

			//导航到Cluster页面
			this.$router.push({
				path: this.groupBreadItems[this.groupBreadIndex].to,
				query: this.groupBreadItems[this.groupBreadIndex].params
			});
		},
		navigate(item) {
			console.log(item)

			if (item.to != '') {
				window.sessionStorage.setItem('groupBreadIndex', item.params.bread_index);
				this.updateDataHandler();
				this.$router.push({
					path: item.to,
					query: item.params
				});
				return true;
			}
			else	//Home按钮，用于隐藏和打开企业面板
			{
				//this.displayGroupPanel = !this.displayGroupPanel;
				return false;
			}

		},
		onRowUnselect(event) {
			//this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 2000 });
			console.log(event);
		},
		updateDataHandler() {
			var jsonStr = window.sessionStorage.getItem('groupBreadItems');
			var _grp_brd_idx = parseInt(window.sessionStorage.getItem('groupBreadIndex'));

			this.groupBreadItems = JSON.parse(jsonStr);

			console.log('update index: ' + _grp_brd_idx)
			console.log('update content: ' + jsonStr)

			for (var i in this.groupBreadItems) {
				this.groupBreadItems[i].style = 'p-menuitem-text';
			}

			console.log(this.groupBreadItems)

			if (this.groupBreadItems != null && this.groupBreadItems.length > 0 && _grp_brd_idx >= 0)
				this.groupBreadItems[_grp_brd_idx].style = 'p-menuitem-text text-cyan-500 font-bold';
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeEnterprise(id) {
			console.log('remove:' + id);
		},
		editEnterprise(id) {
			console.log('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>